import React from "react";

export const TOKEN_ID = "tkn-sections";

const TokenomicsSection = ({ handleTokenomics }) => {
  return (
    <div
      id={TOKEN_ID}
      className="w-full bg-black py-20 px-4 md:px-8 lg:px-16 scroll-m-14"
    >
      <div className="container mx-auto max-w-7xl">
        <h2
          className="text-4xl md:text-5xl lg:text-6xl text-white mb-16 font-bold"
          style={{ fontFamily: "Righteous, cursive" }}
        >
          Tokenomics: Powering the
          <br />
          <span className="bg-gradient-to-r from-pink-500 to-purple-500 text-transparent bg-clip-text">
            Meme Dream
            <span className="inline-block ml-2 animate-pulse">🌈</span>
          </span>
        </h2>

        <div className="grid md:grid-cols-2 gap-8 lg:gap-16">
          {/* Info Cards */}
          <div className="space-y-6">
            {/* Contract Address */}
            <div
              className="bg-gradient-to-r from-pink-500/10 to-purple-500/10 p-6 rounded-xl 
                           backdrop-blur-sm group hover:-translate-y-1 transition-all duration-300
                           hover:from-pink-500/20 hover:to-purple-500/20"
            >
              <h3 className="text-pink-500 text-lg mb-2 font-mono">
                📑 Contract Address
              </h3>
              <div className="flex items-center gap-2">
                <code className="text-gray-300 text-sm break-all">
                  AuFRvtj7noGruqzBBcKfQ18XsxLfmiaW9FpTWPqMpump
                </code>
                <button
                  onClick={() =>
                    navigator.clipboard.writeText(
                      "AuFRvtj7noGruqzBBcKfQ18XsxLfmiaW9FpTWPqMpump"
                    )
                  }
                  className="text-pink-500 hover:text-pink-400 p-2 rounded-lg transition-colors"
                >
                  📋
                </button>
              </div>
            </div>

            {/* Supply */}
            <div
              className="bg-gradient-to-r from-pink-500/10 to-purple-500/10 p-6 rounded-xl 
                           backdrop-blur-sm group hover:-translate-y-1 transition-all duration-300
                           hover:from-pink-500/20 hover:to-purple-500/20"
            >
              <h3 className="text-pink-500 text-lg mb-2 font-mono">
                💰$💰$💰$ Total Supply
              </h3>
              <p className="text-gray-300 font-mono">999,466,319</p>
            </div>

            {/* Key Features Grid */}
            <div className="grid grid-cols-2 gap-4">
              {[
                { label: "Tax", value: "0%" },
                { label: "LP Burned", value: "100%" },
                { label: "Ownership", value: "Renounced" },
                { label: "Driven By", value: "100% Community" },
              ].map((item, index) => (
                <div
                  key={index}
                  className="bg-gradient-to-r from-pink-500/10 to-purple-500/10 p-6 rounded-xl 
                           backdrop-blur-sm group hover:-translate-y-1 transition-all duration-300
                           hover:from-pink-500/20 hover:to-purple-500/20"
                >
                  <h3 className="text-pink-500 text-lg mb-2 font-mono">
                    {item.label}
                  </h3>
                  <p className="text-gray-300 font-mono">{item.value}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Visual Element */}
          <div className="relative group">
            <div
              className="absolute inset-0 bg-gradient-to-r from-pink-500/20 to-purple-500/20 
                          rounded-3xl blur-xl group-hover:blur-2xl transition-all duration-300"
            ></div>
            <div
              className="relative bg-gradient-to-r from-pink-500/10 to-purple-500/10 
                           backdrop-blur-sm rounded-3xl p-8 space-y-6
                           group-hover:from-pink-500/20 group-hover:to-purple-500/20 
                           transition-all duration-300"
            >
              <div className="flex items-center justify-center mb-8">
                <div
                  className="w-24 h-24 bg-gradient-to-r from-pink-500 to-purple-500 
                              rounded-full flex items-center justify-center text-4xl"
                >
                  🚀
                </div>
              </div>

              <div className="space-y-4">
                <h3
                  className="text-2xl text-white text-center font-bold"
                  style={{ fontFamily: "Righteous, cursive" }}
                >
                  Community Driven
                </h3>
                <p className="text-gray-300 text-center">
                  Zero tax, burned liquidity pool, and renounced ownership
                  ensures complete decentralization and community control.
                </p>

                <div className="pt-4 flex justify-center gap-3">
                  <button
                    onClick={handleTokenomics}
                    className="bg-gradient-to-r from-pink-500 to-purple-500 
                                   text-white px-8 py-3 rounded-xl font-bold
                                   hover:opacity-90 transition-opacity"
                    style={{ fontFamily: "Righteous, cursive" }}
                  >
                    View Contract
                  </button>
                  <a
                    href="#how-to-buy"
                    className="bg-gradient-to-r from-pink-500 to-purple-500 
                                   text-white px-8 py-3 rounded-xl font-bold
                                   hover:opacity-90 transition-opacity"
                    style={{ fontFamily: "Righteous, cursive" }}
                  >
                    Buy <span className="text-yellow-300">$HAHA</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenomicsSection;
