import React, { useEffect, useRef, useState } from "react";

const EscapeButton = ({
  duration = 5000,
  onClickSuccess = () => console.log("You caught me!"),
}) => {
  const [isEscaping, setIsEscaping] = useState(true);
  const [position, setPosition] = useState(null);
  const [isShining, setIsShining] = useState(false);
  const buttonRef = useRef(null);
  const originalPosition = useRef(null);

  useEffect(() => {
    if (buttonRef.current && !originalPosition.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      originalPosition.current = {
        x: rect.left,
        y: rect.top,
      };
    }

    // Start shining animation immediately
    setIsShining(true);

    const timer = setTimeout(() => {
      setIsEscaping(false);
      setIsShining(false);
      setPosition(null);
    }, duration);

    // Create rapid shine effect
    const shineInterval = setInterval(() => {
      setIsShining((prev) => !prev);
    }, 200);

    return () => {
      clearTimeout(timer);
      clearInterval(shineInterval);
    };
  }, [duration]);

  const getRandomPosition = () => {
    if (!buttonRef.current) return null;

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const buttonRect = buttonRef.current.getBoundingClientRect();

    const safeMargin = 20;
    const maxX = viewportWidth - buttonRect.width - safeMargin;
    const maxY = viewportHeight - buttonRect.height - safeMargin;

    return {
      x: Math.max(safeMargin, Math.random() * maxX),
      y: Math.max(safeMargin, Math.random() * maxY),
    };
  };

  const handleInteraction = (e) => {
    if (isEscaping) {
      e.preventDefault();
      const newPosition = getRandomPosition();
      if (newPosition) {
        setPosition(newPosition);
      }
    }
  };

  const buttonStyles = position
    ? {
        position: "fixed",
        left: `${position.x}px`,
        top: `${position.y}px`,
        transition: "all 0.3s ease-out",
        transform: "translate3d(0,0,0)",
        willChange: "left, top",
        zIndex: 100,
      }
    : {};

  return (
    <div
      ref={buttonRef}
      style={buttonStyles}
      onMouseOver={isEscaping ? handleInteraction : undefined}
      onTouchStart={isEscaping ? handleInteraction : undefined}
    >
      <button
        onClick={isEscaping ? undefined : onClickSuccess}
        className={`
            transition-all duration-300 px-6 py-2 rounded-full border hover:rotate-3 hover:scale-105 text-black border-pink-500
            relative overflow-hidden
            ${
              isEscaping
                ? `
              bg-gradient-to-r from-yellow-400 via-red-500 to-yellow-400
              hover:from-yellow-500 hover:via-red-600 hover:to-yellow-500
              text-white font-bold
              ${isShining ? "scale-105" : "scale-100"}
              animate-bounce
            `
                : `
              hover:scale-105
              bg-gradient-to-r from-pink-400 via-purple-500 to-pink-400
              hover:from-pink-500 hover:via-purple-600 hover:to-pink-500
              border-transparent
              text-white relative font-bold 
                rounded-full
                transition-all duration-300
                active:scale-95
                before:absolute before:inset-0
                before:rounded-full
                before:animate-[spin_2s_linear_infinite]
                before:bg-gradient-to-r
                before:from-red-500 before:via-yellow-500 before:via-green-500 
                before:via-blue-500 before:to-purple-500
                before:opacity-0
                hover:before:opacity-100
                before:blur-xl
                shadow-[0_0_15px_5px_rgba(59,130,246,0.5)]
                hover:shadow-[0_0_30px_10px_rgba(59,130,246,0.7)]
                group-hover:animate-pulse  
            `
            }
            `}
        style={{
          fontFamily: "Righteous, cursive",
          animation: isEscaping
            ? `
              ${isShining ? "shine 1s infinite" : ""}, 
              ${position && !isEscaping ? "drift 0.5s infinite" : ""}
            `
            : "",
        }}
      >
        {isEscaping ? (
          <>
            Catch me if you can! 🏎️
            <div
              className={`
                absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent
                ${isShining ? "opacity-50" : "opacity-0"}
                transition-opacity duration-200
                transform -skew-x-12
                animate-shine
                rounded-full border 
              `}
            />
            <div className="absolute -right-4 top-1/2 transform -translate-y-1/2">
              <div className="w-8 h-px opacity-75 animate-pulse" />
            </div>
          </>
        ) : (
          "💎 Grab your bag $$"
        )}
      </button>
      <style jsx global>{`
        @keyframes shine {
          0% {
            background-position: 200% center;
          }
          100% {
            background-position: -200% center;
          }
        }
        @keyframes drift {
          0%,
          100% {
            transform: translateX(-2px);
          }
          50% {
            transform: translateX(2px);
          }
        }
      `}</style>
    </div>
  );
};

export default EscapeButton;
