// components/ColorPicker.jsx
import React from "react";
import { BRAND_COLORS } from "../../constants/brandColors";

const ColorPicker = ({ value, onChange, type = "text" }) => {
  const colors =
    type === "text" ? BRAND_COLORS.meme.text : BRAND_COLORS.meme.backgrounds;

  return (
    <div className="flex items-center gap-1">
      {colors.map((color, index) => (
        <button
          key={index}
          onClick={() => onChange(color)}
          className={`w-6 h-6 rounded-full border-2 transition-all duration-200 ${
            value === color
              ? "border-white scale-110"
              : "border-transparent hover:scale-105"
          }`}
          style={{
            background: color,
            boxShadow: value === color ? "0 0 0 2px rgba(0,0,0,0.1)" : "none",
          }}
        />
      ))}
    </div>
  );
};

export default ColorPicker;
