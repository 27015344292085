import React, { useState, useEffect } from "react";

const ContentOverlay = ({
  images = ["/default-image.jpg"], // Default value
  rotationInterval = 5000,
  handleNews,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (images.length <= 1) return;

    const intervalId = setInterval(() => {
      setIsTransitioning(true);

      // Wait for fade out
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
        setIsTransitioning(false);
      }, 300); // Match transition duration
    }, rotationInterval);

    return () => clearInterval(intervalId);
  }, [images.length, rotationInterval]);

  return (
    <>
      <div className="absolute inset-0 w-full h-full flex items-center pointer-events-none">
        <div className="container mx-auto max-w-7xl px-8 md:px-12 lg:px-16">
          <div className="grid md:grid-cols-2 gap-12 lg:gap-20 items-center">
            <div className="space-y-8 lg:space-y-10 pointer-events-auto">
              <h1
                className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold text-white tracking-tight"
                style={{
                  fontFamily: "Righteous, cursive",
                  textShadow: "0 0 20px rgba(255,105,180,0.5)",
                }}
              >
                Stay Up-to-Date
                <br />
                <span className="bg-gradient-to-r from-pink-500 via-purple-500 to-pink-500 text-transparent bg-clip-text">
                  with the Memes
                </span>
              </h1>

              <p
                className="text-white/90 text-lg md:text-xl leading-relaxed tracking-wide"
                style={{ fontFamily: "Righteous, cursive" }}
              >
                Follow the latest news, updates, and developments surrounding
                our meme coin project. From tokenomics and partnerships to new
                features and community events, we'll keep you informed every
                step of the way.
              </p>

              <div className="pt-4">
                <button
                  onClick={handleNews}
                  className="bg-black/80 hover:bg-black text-white px-10 py-4 
                               rounded-lg transition-all duration-300 text-lg 
                               shadow-lg hover:shadow-pink-500/30 font-medium
                               hover:translate-y-[-2px] hover:scale-105"
                  style={{ fontFamily: "Righteous, cursive" }}
                >
                  Learn More
                </button>
              </div>
            </div>

            <div
              className="bg-black/40 backdrop-blur-sm rounded-2xl p-6 pointer-events-auto
                          shadow-xl shadow-purple-500/10 hover:shadow-pink-500/20 transition-all duration-300"
            >
              <div className="relative aspect-square w-full">
                {/* Rotating Image */}
                <img
                  src={images[currentImageIndex]}
                  alt={`Meme ${currentImageIndex + 1}`}
                  className={`absolute inset-0 w-full h-full object-cover rounded-xl transition-opacity duration-300 ${
                    isTransitioning ? "opacity-0" : "opacity-100"
                  }`}
                />

                <div className="absolute inset-0 flex items-center justify-center">
                  <div
                    className="w-4/5 h-4/5 rounded-full border-2 border-purple-500/70 
                                animate-pulse"
                    style={{
                      boxShadow: "0 0 20px #a855f7",
                      animation:
                        "pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite",
                    }}
                  >
                    <div
                      className="w-full h-full rounded-full border-2 border-pink-500/70
                                  animate-pulse"
                      style={{
                        animation:
                          "pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite",
                        animationDelay: "1s",
                      }}
                    >
                      <div
                        className="w-full h-full rounded-full border-2 border-purple-400/50
                                    animate-pulse"
                        style={{
                          animation:
                            "pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite",
                          animationDelay: "2s",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentOverlay;
