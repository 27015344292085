import React, { useEffect, useRef } from "react";
import * as THREE from "three";

const FlippingCoin = ({
  backgroundColor = "#f0f0f0",
  width = "100%",
  height = "400px",
  className = "",
  showFireworks = false,
  fireworksInterval = 5000,
  textureSource = "/haha-example-1.jpg",
  showShadow = true,
  cameraZoom = 1,
}) => {
  const containerRef = useRef(null);
  const sceneRef = useRef(null);
  const frameRef = useRef(null);
  const particlesRef = useRef([]);

  useEffect(() => {
    if (!containerRef.current) return;

    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const camera = new THREE.PerspectiveCamera(
      45,
      containerRef.current.clientWidth / containerRef.current.clientHeight,
      0.1,
      1000
    );

    const baseDistance = 3.2;
    camera.position.set(0, 0, baseDistance * cameraZoom);
    camera.lookAt(0, 0, 0);

    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      powerPreference: "high-performance",
      alpha: true,
    });
    renderer.setClearColor(backgroundColor, 1);
    renderer.setSize(
      containerRef.current.clientWidth,
      containerRef.current.clientHeight
    );
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1.5;
    renderer.shadowMap.enabled = showShadow;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    containerRef.current.appendChild(renderer.domElement);

    const coinRadius = 0.8;
    const coinGeometry = new THREE.CylinderGeometry(
      coinRadius,
      coinRadius,
      0.1,
      32
    );
    coinGeometry.rotateX(Math.PI / 2);

    const textureLoader = new THREE.TextureLoader();
    const dogTexture = textureLoader.load(textureSource, (texture) => {
      texture.minFilter = THREE.LinearFilter;
      texture.magFilter = THREE.LinearFilter;
      texture.encoding = THREE.sRGBEncoding;
      texture.center.set(0.5, 0.5);
      texture.rotation = Math.PI / 2;
    });

    const faceMaterial = new THREE.MeshPhongMaterial({
      map: dogTexture,
      specular: 0x222222,
      shininess: 100,
    });

    const backTexture = dogTexture.clone();
    backTexture.center.set(0.5, 0.5);
    backTexture.rotation = Math.PI / 2;

    const backFaceMaterial = new THREE.MeshPhongMaterial({
      map: backTexture,
      specular: 0x222222,
      shininess: 100,
    });

    const edgeMaterial = new THREE.MeshPhongMaterial({
      color: 0xd4af37,
      specular: 0x222222,
      shininess: 100,
    });

    const coin = new THREE.Mesh(coinGeometry, [
      edgeMaterial,
      faceMaterial,
      backFaceMaterial,
    ]);
    coin.castShadow = showShadow;
    coin.receiveShadow = showShadow;
    scene.add(coin);

    if (showShadow) {
      const shadowPlane = new THREE.Mesh(
        new THREE.PlaneGeometry(5 * cameraZoom, 5 * cameraZoom),
        new THREE.MeshStandardMaterial({
          color: backgroundColor,
          transparent: true,
          opacity: 0.5,
        })
      );
      shadowPlane.position.z = -0.5;
      shadowPlane.receiveShadow = true;
      scene.add(shadowPlane);
    }

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    const frontLight = new THREE.DirectionalLight(0xffffff, 0.8);
    frontLight.position.set(3, 3, 3);
    frontLight.castShadow = showShadow;
    if (showShadow) {
      frontLight.shadow.mapSize.width = 1024;
      frontLight.shadow.mapSize.height = 1024;
      frontLight.shadow.camera.near = 0.5;
      frontLight.shadow.camera.far = 20;
      frontLight.shadow.camera.left = -2 * cameraZoom;
      frontLight.shadow.camera.right = 2 * cameraZoom;
      frontLight.shadow.camera.top = 2 * cameraZoom;
      frontLight.shadow.camera.bottom = -2 * cameraZoom;
    }
    scene.add(frontLight);

    const backLight = new THREE.DirectionalLight(0xffffff, 0.4);
    backLight.position.set(-3, -3, -3);
    scene.add(backLight);

    const fireworkColors = [
      0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff, 0x00ffff, 0xff4500,
      0x7b68ee, 0xff1493, 0x00ff7f,
    ];

    const createFirework = () => {
      const particles = [];
      const particleCount = 150;
      const particleGeometry = new THREE.SphereGeometry(0.03, 8, 8);

      const burstCount = 3;
      for (let burst = 0; burst < burstCount; burst++) {
        const burstColor =
          fireworkColors[Math.floor(Math.random() * fireworkColors.length)];
        const burstOffset = new THREE.Vector3(
          (Math.random() - 0.5) * 0.5,
          (Math.random() - 0.5) * 0.5,
          (Math.random() - 0.5) * 0.5
        );

        for (let i = 0; i < particleCount; i++) {
          const particleMaterial = new THREE.MeshPhongMaterial({
            color: burstColor,
            emissive: burstColor,
            emissiveIntensity: 0.5,
            transparent: true,
            opacity: 1,
          });

          const particle = new THREE.Mesh(particleGeometry, particleMaterial);

          const angle = (i / particleCount) * Math.PI * 2;
          const radius = Math.random() * 0.2;
          const height = Math.random() * 2 - 1;

          particle.position.set(
            coin.position.x + burstOffset.x,
            coin.position.y + burstOffset.y,
            coin.position.z + burstOffset.z
          );

          const velocity = new THREE.Vector3(
            Math.cos(angle) * radius,
            height * 0.1,
            Math.sin(angle) * radius
          );

          velocity.add(
            new THREE.Vector3(
              (Math.random() - 0.5) * 0.2,
              (Math.random() - 0.5) * 0.2,
              (Math.random() - 0.5) * 0.2
            )
          );

          particle.userData.velocity = velocity;
          particle.userData.life = 1.0 + Math.random() * 0.5;
          particle.userData.originalLife = particle.userData.life;
          particle.userData.rotationSpeed = (Math.random() - 0.5) * 0.2;

          scene.add(particle);
          particles.push(particle);
        }
      }
      return particles;
    };

    const flipSpeed = Math.PI * 0.05;
    let time = 0;
    let lastTime = performance.now();
    let lastFireworkTime = 0;

    const animate = (currentTime) => {
      const delta = (currentTime - lastTime) / 1000;
      lastTime = currentTime;
      time += delta;

      coin.rotation.y = time * flipSpeed * 2;

      if (showFireworks && currentTime - lastFireworkTime > fireworksInterval) {
        particlesRef.current = createFirework();
        lastFireworkTime = currentTime;
      }

      particlesRef.current.forEach((particle, index) => {
        if (particle.userData.life > 0) {
          particle.position.add(particle.userData.velocity);
          particle.userData.velocity.y -= 0.01 * delta;
          particle.userData.life -= delta;
          const lifeRatio =
            particle.userData.life / particle.userData.originalLife;
          particle.scale.setScalar(lifeRatio * 0.5 + 0.5);
          particle.material.opacity = lifeRatio;
          particle.rotation.x += particle.userData.rotationSpeed;
          particle.rotation.y += particle.userData.rotationSpeed;
          particle.userData.velocity.multiplyScalar(0.98);
        } else {
          scene.remove(particle);
          particle.geometry.dispose();
          particle.material.dispose();
          particlesRef.current.splice(index, 1);
        }
      });

      renderer.render(scene, camera);
      frameRef.current = requestAnimationFrame(animate);
    };

    const handleResize = () => {
      if (!containerRef.current) return;
      camera.aspect =
        containerRef.current.clientWidth / containerRef.current.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(
        containerRef.current.clientWidth,
        containerRef.current.clientHeight
      );
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(containerRef.current);

    frameRef.current = requestAnimationFrame(animate);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
      if (containerRef.current) {
        containerRef.current.removeChild(renderer.domElement);
      }
      resizeObserver.disconnect();
      renderer.dispose();
      coinGeometry.dispose();
      faceMaterial.dispose();
      backFaceMaterial.dispose();
      edgeMaterial.dispose();
      dogTexture.dispose();
      backTexture.dispose();
      particlesRef.current.forEach((particle) => {
        scene.remove(particle);
        particle.geometry.dispose();
        particle.material.dispose();
      });
    };
  }, [
    backgroundColor,
    showFireworks,
    fireworksInterval,
    textureSource,
    showShadow,
    cameraZoom,
  ]);

  return (
    <div
      ref={containerRef}
      className={`relative ${className}`}
      style={{
        width,
        height,
        backgroundColor,
      }}
    />
  );
};

export default FlippingCoin;
