import React, { useEffect, useState } from "react";

import MemeGenerator from "../memeCreator/mainMemeGenerator";

export const MEME_GENERATOR_ID = "meme-generator-section";

const MemeGeneratorSection = ({
  images = ["/haha-example5.jpg"],
  rotationInterval = 5000,
  handleLaughClick,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isGeneratorOpen, setIsGeneratorOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    if (images.length <= 1) return;

    const intervalId = setInterval(() => {
      setIsTransitioning(true);

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
        setIsTransitioning(false);
      }, 300);
    }, rotationInterval);

    return () => clearInterval(intervalId);
  }, [images.length, rotationInterval]);

  // Prevent body scroll when modal is open
  useEffect(() => {
    if (isGeneratorOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isGeneratorOpen]);

  return (
    <>
      <div
        id={MEME_GENERATOR_ID}
        className="w-full relative py-12 md:py-20 px-4 md:px-8 lg:px-16 scroll-m-14"
        style={{
          background: `linear-gradient(180deg, rgba(0,0,0,1) 0%, #a02863 100%)`,
        }}
      >
        <div className="container mx-auto max-w-7xl relative z-10">
          <div className="grid md:grid-cols-2 gap-8 md:gap-12 lg:gap-20 items-center">
            {/* Left Column - Image */}
            <div className="relative group order-2 md:order-1">
              <div className="relative rounded-3xl overflow-hidden bg-gradient-to-br from-pink-400/20 to-purple-600/20 p-1">
                <div className="absolute inset-0 bg-gradient-to-br from-pink-500/30 to-purple-500/30 animate-pulse"></div>
                <img
                  src={images[currentImageIndex]}
                  alt="Meme Generator"
                  className={`rounded-2xl w-full aspect-square object-cover transform transition-all duration-300 group-hover:scale-105 ${
                    isTransitioning ? "opacity-0" : "opacity-100"
                  }`}
                />
                <div className="absolute top-4 right-4 w-8 h-8 bg-white rounded-full opacity-80"></div>
              </div>
            </div>

            {/* Right Column - Content */}
            <div className="space-y-4 md:space-y-6 lg:space-y-8 order-1 md:order-2">
              <h2
                className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white font-bold leading-tight drop-shadow-lg"
                style={{ fontFamily: "Righteous, cursive" }}
              >
                Meme Generator
              </h2>

              <h3
                className="text-lg md:text-xl text-purple-200 drop-shadow-md"
                style={{ fontFamily: "Righteous, cursive" }}
              >
                Create Your Own
              </h3>

              <p
                className="text-white/90 text-base md:text-lg leading-relaxed font-mono drop-shadow-md 
                          backdrop-blur-sm bg-black/10 rounded-xl p-4"
              >
                Unleash your creativity and bring your meme ideas to life with
                our intuitive meme content generator. Customize templates, add
                text, and share your masterpieces with the community. Become a
                meme legend today!
              </p>

              {/* <button
                onClick={() => setIsGeneratorOpen(true)}
                className="bg-white/95 hover:bg-white text-black px-6 md:px-8 py-2.5 md:py-3 rounded-xl
                          text-base md:text-lg font-semibold transition-all duration-300
                          hover:shadow-lg hover:shadow-pink-500/20 hover:-translate-y-1
                          backdrop-blur-sm w-full md:w-auto"
                style={{ fontFamily: "Righteous, cursive" }}
              >
                Get Started
              </button> */}
              <button
                onClick={handleLaughClick}
                className="bg-white/95 hover:bg-white/30 text-black px-6 md:px-8 py-2.5 md:py-3 rounded-xl
                          text-base md:text-lg font-semibold transition-all duration-300
                          hover:shadow-lg hover:shadow-pink-500/20 
                          backdrop-blur-sm w-full md:w-auto"
                style={{ fontFamily: "Righteous, cursive" }}
              >
                Coming soon
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Meme Generator Modal */}
      {isGeneratorOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 p-0 md:p-4 lg:p-8">
          <div
            className={`relative bg-[#13111C] ${
              isMobile ? "w-full h-full" : "w-[95%] h-[90%] rounded-lg"
            } overflow-hidden`}
          >
            {/* Close button - Adjusted for mobile */}
            <button
              onClick={() => setIsGeneratorOpen(false)}
              className={`absolute ${
                isMobile ? "top-2 right-2" : "top-4 right-4"
              } z-50 p-2 bg-white/10 hover:bg-white/20 rounded-lg text-white transition-colors`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`${isMobile ? "h-5 w-5" : "h-6 w-6"}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Scroll container with touch handling */}
            <div
              className={`w-full h-full overflow-auto overscroll-none ${
                isMobile ? "pb-20" : "pb-8"
              }`}
              style={{
                WebkitOverflowScrolling: "touch",
                msOverflowStyle: "-ms-autohiding-scrollbar",
              }}
            >
              <MemeGenerator />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MemeGeneratorSection;
