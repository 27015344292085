import {
  Download,
  Image as ImageIcon,
  Maximize,
  Move,
  MoveDown,
  MoveUp,
  RotateCw,
  Type as TypeIcon,
  X,
} from "lucide-react";
import React, { useCallback, useRef, useState } from "react";

import { CONTROL_MODES } from "../../constants/controls";
import ColorPicker from "../ui/colorPicker";
import AssetSelector from "./assetSelector";

const MemeGenerator = () => {
  const [layers, setLayers] = useState([]);
  const [selectedLayerId, setSelectedLayerId] = useState(null);
  const [controlMode, setControlMode] = useState(CONTROL_MODES.NONE);
  const [backgroundColor, setBackgroundColor] = useState("#13111C");
  const canvasRef = useRef(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          const newLayer = {
            type: "image",
            content: event.target.result,
            position: { x: 50, y: 50 },
            size: { width: 200, height: 200 / aspectRatio },
            aspectRatio,
            rotation: 0, // Add rotation property
            id: Date.now(),
            zIndex: layers.length,
          };
          setLayers([...layers, newLayer]);
          setSelectedLayerId(newLayer.id);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  // Same for template selection
  const handleAssetSelect = (assetPath) => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const newLayer = {
        type: "image",
        content: assetPath,
        position: { x: 50, y: 50 },
        size: { width: 200, height: 200 / aspectRatio },
        aspectRatio,
        id: Date.now(),
        zIndex: layers.length,
      };
      setLayers([...layers, newLayer]);
      setSelectedLayerId(newLayer.id);
    };
    img.src = assetPath;
  };

  const addText = () => {
    const newLayer = {
      type: "text",
      content: "Enter text",
      position: { x: 50, y: 50 },
      color: "#ffffff",
      fontSize: 24,
      id: Date.now(),
      zIndex: layers.length,
      size: { width: 100, height: 40 }, // Added minimum dimensions
    };
    setLayers([...layers, newLayer]);
    setSelectedLayerId(newLayer.id);
    setControlMode(CONTROL_MODES.NONE);
  };

  // Add rotation handler
  const handleRotateStart = useCallback(
    (e, layer) => {
      if (controlMode !== CONTROL_MODES.ROTATE) return;

      e.stopPropagation();
      const isTouchEvent = e.type.startsWith("touch");
      const startX = isTouchEvent ? e.touches[0].clientX : e.clientX;
      const startY = isTouchEvent ? e.touches[0].clientY : e.clientY;

      // Get the center of the image
      const rect = e.currentTarget.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      // Calculate the starting angle
      const startAngle = Math.atan2(startY - centerY, startX - centerX);
      const initialRotation = layer.rotation || 0;

      const handleMove = (moveEvent) => {
        moveEvent.preventDefault();
        const moveClientX = moveEvent.touches
          ? moveEvent.touches[0].clientX
          : moveEvent.clientX;
        const moveClientY = moveEvent.touches
          ? moveEvent.touches[0].clientY
          : moveEvent.clientY;

        const currentAngle = Math.atan2(
          moveClientY - centerY,
          moveClientX - centerX
        );
        let deltaDegrees = (currentAngle - startAngle) * (180 / Math.PI);

        // Snap to 15-degree increments when holding shift
        if (moveEvent.shiftKey) {
          deltaDegrees = Math.round(deltaDegrees / 15) * 15;
        }

        const newRotation = initialRotation + deltaDegrees;

        setLayers((prev) =>
          prev.map((l) =>
            l.id === layer.id ? { ...l, rotation: newRotation } : l
          )
        );
      };

      const handleEnd = () => {
        document.removeEventListener("mousemove", handleMove);
        document.removeEventListener("mouseup", handleEnd);
        document.removeEventListener("touchmove", handleMove);
        document.removeEventListener("touchend", handleEnd);
      };

      if (isTouchEvent) {
        document.addEventListener("touchmove", handleMove, { passive: false });
        document.addEventListener("touchend", handleEnd);
      } else {
        document.addEventListener("mousemove", handleMove);
        document.addEventListener("mouseup", handleEnd);
      }
    },
    [controlMode]
  );

  const handleInteractionStart = useCallback(
    (e, identifier) => {
      if (controlMode !== CONTROL_MODES.MOVE) return;

      const isTouchEvent = e.type.startsWith("touch");
      const clientX = isTouchEvent ? e.touches[0].clientX : e.clientX;
      const clientY = isTouchEvent ? e.touches[0].clientY : e.clientY;

      const rect = canvasRef.current.getBoundingClientRect();
      const layer = layers.find((l) => l.id === identifier);
      const layerRect = e.currentTarget.getBoundingClientRect();

      const offsetX = clientX - layerRect.left;
      const offsetY = clientY - layerRect.top;

      const handleMove = (moveEvent) => {
        moveEvent.preventDefault();
        const moveClientX = moveEvent.touches
          ? moveEvent.touches[0].clientX
          : moveEvent.clientX;
        const moveClientY = moveEvent.touches
          ? moveEvent.touches[0].clientY
          : moveEvent.clientY;

        const x = moveClientX - rect.left - offsetX;
        const y = moveClientY - rect.top - offsetY;

        setLayers((prev) =>
          prev.map((layer) =>
            layer.id === identifier
              ? {
                  ...layer,
                  position: {
                    x: Math.max(
                      0,
                      Math.min(x, rect.width - (layer.size?.width || 100))
                    ),
                    y: Math.max(
                      0,
                      Math.min(y, rect.height - (layer.size?.height || 50))
                    ),
                  },
                }
              : layer
          )
        );
      };

      const handleEnd = () => {
        document.removeEventListener("mousemove", handleMove);
        document.removeEventListener("mouseup", handleEnd);
        document.removeEventListener("touchmove", handleMove);
        document.removeEventListener("touchend", handleEnd);
      };

      if (isTouchEvent) {
        document.addEventListener("touchmove", handleMove, { passive: false });
        document.addEventListener("touchend", handleEnd);
      } else {
        document.addEventListener("mousemove", handleMove);
        document.addEventListener("mouseup", handleEnd);
      }
    },
    [controlMode, layers]
  );

  const handleResizeStart = useCallback(
    (e, layer) => {
      if (controlMode !== CONTROL_MODES.RESIZE) return;

      e.stopPropagation();
      const isTouchEvent = e.type.startsWith("touch");
      const startX = isTouchEvent ? e.touches[0].clientX : e.clientX;
      const startY = isTouchEvent ? e.touches[0].clientY : e.clientY;

      const startSize =
        layer.type === "image"
          ? { width: layer.size.width, height: layer.size.height }
          : { fontSize: layer.fontSize };

      const handleMove = (moveEvent) => {
        moveEvent.preventDefault();
        const moveClientX = moveEvent.touches
          ? moveEvent.touches[0].clientX
          : moveEvent.clientX;
        const moveClientY = moveEvent.touches
          ? moveEvent.touches[0].clientY
          : moveEvent.clientY;

        const deltaX = moveClientX - startX;

        if (layer.type === "image") {
          const newWidth = Math.max(100, startSize.width + deltaX);
          const newHeight = newWidth / layer.aspectRatio;

          setLayers((prev) =>
            prev.map((l) =>
              l.id === layer.id
                ? {
                    ...l,
                    size: {
                      width: newWidth,
                      height: newHeight,
                    },
                  }
                : l
            )
          );
        } else {
          const deltaY = moveClientY - startY;
          setLayers((prev) =>
            prev.map((l) =>
              l.id === layer.id
                ? {
                    ...l,
                    fontSize: Math.max(
                      12,
                      Math.min(72, startSize.fontSize + deltaY * 0.5)
                    ),
                  }
                : l
            )
          );
        }
      };

      const handleEnd = () => {
        document.removeEventListener("mousemove", handleMove);
        document.removeEventListener("mouseup", handleEnd);
        document.removeEventListener("touchmove", handleMove);
        document.removeEventListener("touchend", handleEnd);
      };

      if (isTouchEvent) {
        document.addEventListener("touchmove", handleMove, { passive: false });
        document.addEventListener("touchend", handleEnd);
      } else {
        document.addEventListener("mousemove", handleMove);
        document.addEventListener("mouseup", handleEnd);
      }
    },
    [controlMode]
  );

  const handleLayerUpdate = (id, action) => {
    const currentIndex = layers.findIndex((layer) => layer.id === id);
    const newLayers = [...layers];

    switch (action) {
      case "moveUp":
        if (currentIndex < layers.length - 1) {
          [newLayers[currentIndex], newLayers[currentIndex + 1]] = [
            newLayers[currentIndex + 1],
            newLayers[currentIndex],
          ];
          newLayers.forEach((layer, index) => {
            layer.zIndex = index;
          });
          setLayers(newLayers);
        }
        break;

      case "moveDown":
        if (currentIndex > 0) {
          [newLayers[currentIndex], newLayers[currentIndex - 1]] = [
            newLayers[currentIndex - 1],
            newLayers[currentIndex],
          ];
          newLayers.forEach((layer, index) => {
            layer.zIndex = index;
          });
          setLayers(newLayers);
        }
        break;

      case "delete":
        setLayers(layers.filter((layer) => layer.id !== id));
        setSelectedLayerId(null);
        setControlMode(CONTROL_MODES.NONE);
        break;

      default:
        break;
    }
  };

  const handleColorChange = (id, color) => {
    setLayers((prev) =>
      prev.map((layer) => (layer.id === id ? { ...layer, color } : layer))
    );
  };

  const handleTextChange = (id, content) => {
    setLayers((prev) =>
      prev.map((layer) => (layer.id === id ? { ...layer, content } : layer))
    );
  };

  const downloadMeme = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const canvasRect = canvasRef.current.getBoundingClientRect();

    // Set canvas size (can increase for better quality)
    const scaleFactor = 2; // Increase output resolution
    canvas.width = canvasRect.width * scaleFactor;
    canvas.height = canvasRect.height * scaleFactor;

    // Scale context for higher resolution
    context.scale(scaleFactor, scaleFactor);

    // Fill background
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, canvasRect.width, canvasRect.height);

    // Draw layers with proper image handling
    const drawLayerPromises = [...layers]
      .sort((a, b) => a.zIndex - b.zIndex)
      .map((layer) => {
        return new Promise((resolve) => {
          if (layer.type === "image") {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.onload = () => {
              context.save();
              // Move to center of image position
              context.translate(
                layer.position.x + layer.size.width / 2,
                layer.position.y + layer.size.height / 2
              );
              // Rotate
              context.rotate((layer.rotation * Math.PI) / 180);
              // Draw image centered
              context.drawImage(
                img,
                -layer.size.width / 2,
                -layer.size.height / 2,
                layer.size.width,
                layer.size.height
              );
              context.restore();
              resolve();
            };
            img.onerror = () => {
              console.error("Error loading image:", layer.content);
              resolve();
            };
            img.src = layer.content;
          } else if (layer.type === "text") {
            context.font = `${layer.fontSize}px Righteous`;
            context.fillStyle = layer.color;
            context.textAlign = "center";
            const x = layer.position.x + layer.size.width / 2;
            const y = layer.position.y + layer.size.height / 2;
            context.fillText(layer.content, x, y);
            resolve();
          }
        });
      });

    // Wait for all images to be drawn, then download
    Promise.all(drawLayerPromises).then(() => {
      const link = document.createElement("a");
      link.download = "meme.png";
      link.href = canvas.toDataURL("image/png", 1.0);
      link.click();
    });
  };

  const renderControls = (layer) => (
    <div className="absolute -top-12 left-0 flex items-center gap-2 bg-[#1A1825] p-2 rounded-lg shadow-lg">
      <button
        onClick={() =>
          setControlMode(
            controlMode === CONTROL_MODES.MOVE
              ? CONTROL_MODES.NONE
              : CONTROL_MODES.MOVE
          )
        }
        className={`p-1 rounded ${
          controlMode === CONTROL_MODES.MOVE
            ? "bg-green-600"
            : "hover:bg-purple-600"
        }`}
      >
        <Move size={16} />
      </button>
      <button
        onClick={() =>
          setControlMode(
            controlMode === CONTROL_MODES.RESIZE
              ? CONTROL_MODES.NONE
              : CONTROL_MODES.RESIZE
          )
        }
        className={`p-1 rounded ${
          controlMode === CONTROL_MODES.RESIZE
            ? "bg-green-600"
            : "hover:bg-purple-600"
        }`}
      >
        <Maximize size={16} />
      </button>
      <button
        onClick={() =>
          setControlMode(
            controlMode === CONTROL_MODES.ROTATE
              ? CONTROL_MODES.NONE
              : CONTROL_MODES.ROTATE
          )
        }
        className={`p-1 rounded ${
          controlMode === CONTROL_MODES.ROTATE
            ? "bg-green-600"
            : "hover:bg-purple-600"
        }`}
      >
        <RotateCw size={16} />
      </button>
      {layer.type === "text" && (
        <ColorPicker
          value={layer.color}
          onChange={(color) => handleColorChange(layer.id, color)}
          type="text"
        />
      )}
      <button
        onClick={() =>
          setControlMode(
            controlMode === CONTROL_MODES.MOVE
              ? CONTROL_MODES.NONE
              : CONTROL_MODES.MOVE
          )
        }
        className={`p-1 rounded ${
          controlMode === CONTROL_MODES.MOVE
            ? "bg-green-600"
            : "hover:bg-purple-600"
        }`}
      >
        <Move size={16} />
      </button>
      <button
        onClick={() =>
          setControlMode(
            controlMode === CONTROL_MODES.RESIZE
              ? CONTROL_MODES.NONE
              : CONTROL_MODES.RESIZE
          )
        }
        className={`p-1 rounded ${
          controlMode === CONTROL_MODES.RESIZE
            ? "bg-green-600"
            : "hover:bg-purple-600"
        }`}
      >
        <Maximize size={16} />
      </button>
      <div className="w-px h-6 bg-gray-600" />
      <button
        onClick={() => handleLayerUpdate(layer.id, "moveDown")}
        className="p-1 rounded hover:bg-purple-600"
      >
        <MoveDown size={16} />
      </button>
      <button
        onClick={() => handleLayerUpdate(layer.id, "moveUp")}
        className="p-1 rounded hover:bg-purple-600"
      >
        <MoveUp size={16} />
      </button>
      <button
        onClick={() => handleLayerUpdate(layer.id, "delete")}
        className="p-1 rounded hover:bg-pink-600"
      >
        <X size={16} />
      </button>
    </div>
  );

  const renderLayer = (layer) => {
    const isSelected = selectedLayerId === layer.id;

    const commonProps = {
      className: `absolute ${
        controlMode === CONTROL_MODES.MOVE ? "cursor-move" : "cursor-default"
      } ${isSelected ? "ring-2 ring-blue-500" : ""}`,
      style: {
        left: layer.position.x,
        top: layer.position.y,
        zIndex: layer.zIndex,
      },
      onClick: (e) => {
        e.stopPropagation();
        setSelectedLayerId(layer.id);
      },
      onMouseDown: (e) => handleInteractionStart(e, layer.id),
      onTouchStart: (e) => handleInteractionStart(e, layer.id),
    };

    if (layer.type === "image") {
      return (
        <div key={layer.id} {...commonProps}>
          <div
            className="relative"
            style={{
              transform: `rotate(${layer.rotation}deg)`,
              transformOrigin: "center center",
            }}
          >
            <img
              src={layer.content}
              alt="Layer"
              style={{
                width: layer.size.width,
                height: layer.size.height,
                objectFit: "cover",
              }}
              className="rounded-lg ring-2 ring-pink-500/30"
              draggable={false}
            />
            {isSelected && (
              <>
                {renderControls(layer)}
                {controlMode === CONTROL_MODES.ROTATE && (
                  <div
                    className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-8 w-8 h-8 
                              bg-white/80 rounded-full cursor-move flex items-center justify-center 
                              touch-none"
                    onMouseDown={(e) => handleRotateStart(e, layer)}
                    onTouchStart={(e) => handleRotateStart(e, layer)}
                  >
                    <RotateCw size={16} className="text-black" />
                  </div>
                )}
                {controlMode === CONTROL_MODES.RESIZE && (
                  <div
                    className="absolute bottom-0 right-0 w-8 h-8 bg-white/80 rounded-full 
                              cursor-se-resize flex items-center justify-center touch-none"
                    onMouseDown={(e) => handleResizeStart(e, layer)}
                    onTouchStart={(e) => handleResizeStart(e, layer)}
                  >
                    <Maximize size={16} className="text-black" />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      );
    }

    return (
      <div key={layer.id} {...commonProps}>
        <div className="relative">
          <input
            value={layer.content}
            onChange={(e) => handleTextChange(layer.id, e.target.value)}
            className="bg-transparent border-none p-0 focus:outline-none focus:ring-0 font-['Righteous'] max-w-full"
            style={{
              color: layer.color,
              fontSize: `${layer.fontSize}px`,
            }}
          />
          {isSelected && (
            <>
              {renderControls(layer)}
              {controlMode === CONTROL_MODES.RESIZE && (
                <div
                  className="absolute bottom-0 right-0 w-8 h-8 bg-white/80 rounded-full cursor-ns-resize flex items-center justify-center touch-none"
                  onMouseDown={(e) => handleResizeStart(e, layer)}
                  onTouchStart={(e) => handleResizeStart(e, layer)}
                >
                  <Maximize size={16} className="text-black" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className="min-h-screen text-white font-['Righteous']"
      style={{ backgroundColor }}
    >
      <div className="max-w-6xl mx-auto p-2 md:p-6">
        <h1 className="text-xl md:text-4xl mb-4 md:mb-6 text-center bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 text-transparent bg-clip-text">
          Meme Generator
        </h1>

        <div className="space-y-4">
          <div className="flex flex-wrap gap-2 md:gap-4">
            <AssetSelector onSelect={handleAssetSelect} />
            <button
              onClick={() => document.getElementById("imageUpload").click()}
              className="flex items-center gap-1 md:gap-2 px-3 md:px-4 py-2 bg-pink-600 hover:bg-pink-700 rounded-lg transition-colors text-sm md:text-base"
            >
              <ImageIcon size={16} />
              <span className="hidden md:inline">Add Image</span>
              <span className="md:hidden">Image</span>
            </button>
            <input
              id="imageUpload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
            <button
              onClick={addText}
              className="flex items-center gap-1 md:gap-2 px-3 md:px-4 py-2 bg-purple-600 hover:bg-purple-700 rounded-lg transition-colors text-sm md:text-base"
            >
              <TypeIcon size={16} />
              <span className="hidden md:inline">Add Text</span>
              <span className="md:hidden">Text</span>
            </button>
            <div className="flex items-center gap-2">
              <label className="text-sm hidden md:inline">Background:</label>
              <ColorPicker
                value={backgroundColor}
                onChange={setBackgroundColor}
                type="background"
              />
            </div>
            <button
              onClick={downloadMeme}
              className="flex items-center gap-1 md:gap-2 px-3 md:px-4 py-2 bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors ml-auto text-sm md:text-base"
            >
              <Download size={16} />
              <span className="hidden md:inline">Download</span>
              <span className="md:hidden">Save</span>
            </button>
          </div>

          <div
            ref={canvasRef}
            className="relative border-2 border-dashed border-purple-500/30 rounded-lg h-[calc(100vh-12rem)] md:h-[600px] touch-none"
            style={{ backgroundColor }}
            onClick={() => {
              setSelectedLayerId(null);
              setControlMode(CONTROL_MODES.NONE);
            }}
          >
            {[...layers].sort((a, b) => a.zIndex - b.zIndex).map(renderLayer)}

            {selectedLayerId && controlMode !== CONTROL_MODES.NONE && (
              <div className="absolute bottom-4 right-4 bg-[#1A1825] px-3 py-2 rounded-lg text-sm">
                {controlMode === CONTROL_MODES.MOVE
                  ? "Drag to move"
                  : "Drag handle to resize"}
              </div>
            )}
          </div>

          {layers.length === 0 && (
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white/50 text-center">
              <p className="text-sm md:text-base">
                Add an image or text to get started
              </p>
              <p className="text-xs md:text-sm mt-2">Click the buttons above</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemeGenerator;
