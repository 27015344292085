import { Menu, X } from "lucide-react";
import React, { useEffect, useState } from "react";

import AboutSection, { ABOUT_ID } from "../components/ui/aboutSection";
import EscapeButton from "../components/ui/escapeButton";
import FlippingCoin2 from "../components/ui/flippingCoinTwo";
import FloatingRoomLayout from "../components/ui/floatingRoom";
import Footer from "../components/ui/footer";
import HowToBuySection, { HOW_TO_ID } from "../components/ui/howtoBuySection";
import MemeGeneratorSection, {
  MEME_GENERATOR_ID,
} from "../components/ui/memeGeneratorSection";
import HeroRotator from "../components/ui/rotateComponents";
import TokenomicsSection, { TOKEN_ID } from "../components/ui/tokenomics";
import Wave from "../components/ui/wave";
import { laughAction } from "../utils";

const LandingPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [laughCount, setLaughCount] = useState(0);

  const laughEmojis = ["😂", "🤣", "😆", "😹", "🐕"];
  const getRandomEmoji = () =>
    laughEmojis[Math.floor(Math.random() * laughEmojis.length)];

  const grabYourBag = () => {
    laughAction(
      setLaughCount,
      window.open,
      "https://jup.ag/swap/SOL-AuFRvtj7noGruqzBBcKfQ18XsxLfmiaW9FpTWPqMpump"
    );
  };

  const handleNews = () => {
    laughAction(setLaughCount, window.open, "https://x.com/HahaDogSol");
  };

  const joinThePack = () => {
    laughAction(
      setLaughCount,
      () =>
        document
          .getElementById(ABOUT_ID)
          .scrollIntoView({ behavior: "smooth" }),
      null,
      10
    );
  };

  const handleTokenomics = () => {
    laughAction(
      setLaughCount,
      window.open,
      "https://solscan.io/token/AuFRvtj7noGruqzBBcKfQ18XsxLfmiaW9FpTWPqMpump"
    );
  };

  const handleLaughClick = () => {
    setLaughCount((prev) => prev + 1);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      {/* Navigation Bar */}
      <nav
        className={`fixed w-full z-50 transition-all duration-300 ${
          isScrolled ? "bg-black shadow-lg py-3" : "bg-transparent py-4"
        }`}
      >
        <div className="container mx-auto px-6 flex items-center justify-between">
          <div className="flex items-center">
            <span
              className={`text-2xl font-bold transition-colors duration-300 ${
                isScrolled ? "text-white" : "text-gray-900"
              }`}
              style={{ fontFamily: "Righteous, cursive" }}
            >
              <div className="flex items-center justify-center align-middle">
                <img
                  src="/hahadog.png"
                  alt="HahaDogsol Logo"
                  className="w-16"
                />
                HAHA$
              </div>
            </span>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {[
              { item: "Home", url: "" },
              { item: "Tokenomics", url: `${TOKEN_ID}` },
              { item: "Meme Generator", url: `${MEME_GENERATOR_ID}` },
              { item: "About", url: `${ABOUT_ID}` },
              { item: "Buy", url: HOW_TO_ID },
            ].map(({ item, url }) => (
              <a
                key={item}
                href={`#${url}`}
                className={`transition-colors duration-300  ${
                  isScrolled
                    ? "text-gray-400 hover:text-white"
                    : "text-gray-700 hover:text-purple-500"
                }`}
                style={{ fontFamily: "Righteous, cursive" }}
              >
                {item}
              </a>
            ))}

            <EscapeButton
              duration={5000}
              onClickSuccess={grabYourBag}
              isScrolled={isScrolled}
            />
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden"
            onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}
          >
            {isMenuOpen ? (
              <X className={isScrolled ? "text-white" : "text-black"} />
            ) : (
              <Menu className={isScrolled ? "text-white" : "text-black"} />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden absolute top-full left-0 right-0 bg-white shadow-lg">
            <div className="flex flex-col space-y-4 px-6 py-6">
              {[
                { item: "Home", url: "" },
                { item: "Tokenomics", url: `${TOKEN_ID}` },
                { item: "Meme Generator", url: `${MEME_GENERATOR_ID}` },
                { item: "About", url: `${ABOUT_ID}` },
                { item: "Buy", url: HOW_TO_ID },
              ].map(({ item, url }) => (
                <span
                  onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}
                >
                  <a
                    key={item}
                    href={`#${url}`}
                    className="text-gray-800 hover:text-gray-600"
                  >
                    {item}
                  </a>
                </span>
              ))}
              <EscapeButton
                duration={5000}
                onClickSuccess={grabYourBag}
                isScrolled={false}
              />
            </div>
          </div>
        )}
      </nav>

      {/* Floating Emojis */}
      {Array.from({ length: laughCount }).map((_, i) => (
        <div
          key={i}
          className="fixed z-50 text-5xl pointer-events-none"
          style={{
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            animation: `float-up ${2 + Math.random() * 2}s linear forwards`,
          }}
        >
          {getRandomEmoji()}
        </div>
      ))}

      {/* Hero Section */}
      <div className="relative">
        <div className="container mx-auto px-6 pt-32 pb-48">
          <div className="flex flex-col md:flex-row items-center justify-between">
            {/* Left Content */}
            <div className="w-full md:w-1/2 text-center md:text-left">
              <h1
                className="text-5xl font-bold leading-tight mb-6 transition-transform hover:scale-105"
                style={{ fontFamily: "Righteous, cursive" }}
              >
                Warning:
                <br />
                Schadenfreude
                <br />
                Ahead! 😈
              </h1>

              <p
                className="text-gray-600 mb-8 max-w-md mx-auto md:mx-0"
                style={{ fontFamily: "Righteous, cursive" }}
              >
                Meet HahaDog: Your favorite judgmental meme turned crypto. No
                devs, no mercy - just pure, unadulterated laughter at others'
                expense. Not holding? Better watch your step, because the next
                viral fail might be yours!
              </p>
              <button
                onClick={joinThePack}
                className="bg-pink-400 cursor-pointer text-white px-8 py-3 rounded-full hover:bg-pink-500 transition-all hover:scale-105 hover:rotate-1"
              >
                Join the Pack 🐕
                {/* <span className="ml-2 text-sm">*bork bork*</span> */}
              </button>
            </div>

            {/* Right Content - 3D Token */}
            <div className="w-full md:w-1/2 flex justify-center md:justify-center mt-12 md:mt-0">
              <HeroRotator interval={7000} containerHeight="h-[500px]">
                <FlippingCoin2
                  backgroundColor="#ffffff"
                  height="550px"
                  className="rounded-lg"
                  textureSource="/general/2.jpg"
                  showFireworks={true}
                  fireworksInterval={5000}
                  showShadow={false}
                  cameraZoom={0.9}
                />
                <FlippingCoin2
                  backgroundColor="#ffffff"
                  height="550px"
                  className="rounded-lg"
                  textureSource="/general/3.jpg"
                  showFireworks={true}
                  fireworksInterval={5000}
                  showShadow={false}
                  cameraZoom={0.9}
                />
                <FlippingCoin2
                  backgroundColor="#ffffff"
                  height="550px"
                  className="rounded-lg"
                  textureSource="/general/4.jpg"
                  showFireworks={true}
                  fireworksInterval={5000}
                  showShadow={false}
                  cameraZoom={0.9}
                />
                <FlippingCoin2
                  backgroundColor="#ffffff"
                  height="550px"
                  className="rounded-lg"
                  textureSource="/general/5.jpg"
                  showFireworks={true}
                  fireworksInterval={5000}
                  showShadow={false}
                  cameraZoom={0.9}
                />
                <FlippingCoin2
                  backgroundColor="#ffffff"
                  height="550px"
                  className="rounded-lg"
                  textureSource="/general/6.jpg"
                  showFireworks={true}
                  fireworksInterval={5000}
                  showShadow={false}
                  cameraZoom={0.9}
                />
                <FlippingCoin2
                  backgroundColor="#ffffff"
                  height="550px"
                  className="rounded-lg"
                  textureSource="/general/7.jpg"
                  showFireworks={true}
                  fireworksInterval={5000}
                  showShadow={false}
                  cameraZoom={0.9}
                />
                <FlippingCoin2
                  backgroundColor="#ffffff"
                  height="550px"
                  className="rounded-lg "
                  showFireworks={true}
                  fireworksInterval={5000}
                  textureSource="/general/1.jpg"
                  showShadow={false}
                  cameraZoom={0.9}
                />
                <FlippingCoin2
                  backgroundColor="#ffffff"
                  height="550px"
                  className="rounded-lg"
                  textureSource="/general/8.jpg"
                  showFireworks={true}
                  fireworksInterval={5000}
                  showShadow={false}
                  cameraZoom={0.9}
                />
                <FlippingCoin2
                  backgroundColor="#ffffff"
                  height="550px"
                  className="rounded-lg"
                  textureSource="/general/9.jpg"
                  showFireworks={true}
                  fireworksInterval={5000}
                  showShadow={false}
                  cameraZoom={0.9}
                />
                <FlippingCoin2
                  backgroundColor="#ffffff"
                  height="550px"
                  className="rounded-lg"
                  textureSource="/general/10.jpg"
                  showFireworks={true}
                  fireworksInterval={5000}
                  showShadow={false}
                  cameraZoom={0.9}
                />
              </HeroRotator>
            </div>
          </div>
        </div>

        {/* Enhanced Wave Decoration */}
        <Wave />
      </div>
      <style jsx global>{`
        @keyframes float-up {
          0% {
            transform: translateY(0) rotate(0deg);
            opacity: 1;
          }
          100% {
            transform: translateY(-100vh) rotate(360deg);
            opacity: 0;
          }
        }

        @keyframes pulse {
          0% {
            transform: rotate(12deg) scale(1);
          }
          50% {
            transform: rotate(12deg) scale(1.02);
          }
          100% {
            transform: rotate(12deg) scale(1);
          }
        }
      `}</style>

      <div className="bg-black text-white min-h-screen flex items-center justify-center">
        <TokenomicsSection handleTokenomics={handleTokenomics} />
      </div>
      <HowToBuySection joinThePack={joinThePack} />
      <MemeGeneratorSection
        images={[
          "/general/26.jpg",
          "/general/25.jpg",
          "/general/24.jpg",
          "/general/23.jpg",
          "/general/22.jpg",
          "/general/21.jpg",
          "/general/20.jpg",
          "/general/19.jpg",
          "/general/18.jpg",
          "/general/17.jpg",
          "/general/16.jpg",
          "/general/15.jpg",
          "/general/14.jpg",
          "/general/13.jpg",
          "/general/12.jpg",
          "/general/11.jpg",
          "/general/10.jpg",
          "/general/9.jpg",
          "/general/8.jpg",
          "/general/7.jpg",
          "/general/6.jpg",
          "/general/5.jpg",
        ]}
        handleLaughClick={handleLaughClick}
        rotationInterval={3000}
      />

      <FloatingRoomLayout handleNews={handleNews} />
      <AboutSection />
      <Footer handleLaughClick={handleLaughClick} />
    </div>
  );
};

export default LandingPage;
