import React from "react";

const Footer = ({ handleLaughClick }) => {
  return (
    <footer className="bg-black pt-20 pb-10 px-4 md:px-8 lg:px-16">
      <div className="container mx-auto max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12 md:gap-8">
          {/* Logo and Copyright Column */}
          <div className="space-y-6">
            <div className="flex items-center">
              <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
                <span className="text-2xl">👽</span>
              </div>
            </div>
            <div className="text-gray-500 text-sm font-mono">
              © 2024 HAHA$, Inc.
              <br />
              All rights reserved.
            </div>
          </div>

          {/* Quick Links */}
          <div className="space-y-4">
            <h3 className="text-gray-400 text-lg font-mono mb-4">
              Quick Links
            </h3>
            <ul className="space-y-3">
              {[
                { item: "Home", url: "#" },
                {
                  item: "Buy",
                  url: "https://jup.ag/swap/SOL-AuFRvtj7noGruqzBBcKfQ18XsxLfmiaW9FpTWPqMpump",
                },
                { item: "FAQ", url: "#" },
              ].map(({ item, url }) => (
                <li key={item}>
                  <a
                    href={url}
                    target={url === "#" ? "_blank" : "_self"}
                    className="text-gray-500 hover:text-white transition-colors font-mono"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Connect with Us */}
          <div className="space-y-4">
            <h3 className="text-gray-400 text-lg font-mono mb-4">
              Connect with Us
            </h3>
            <ul className="space-y-3">
              {[
                { item: "Telegram", url: "https://t.me/HahaCTO" },
                { item: "Tiktok", url: "https://www.tiktok.com/@hahadogmeme" },
                {
                  item: "Instagram",
                  url: "https://www.instagram.com/hahadogmeme",
                },
                { item: "X", url: "https://x.com/HahaDogSol" },
              ].map(({ item, url }) => (
                <li key={item}>
                  <a
                    href={url}
                    target="_blank"
                    className="text-gray-500 hover:text-white transition-colors font-mono"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Resources */}
          <div className="space-y-4">
            <h3 className="text-gray-400 text-lg font-mono mb-4">Resources</h3>
            <ul className="space-y-3">
              {["Blog"].map((item) => (
                <li key={item}>
                  <button
                    onClick={handleLaughClick}
                    className="text-gray-500 hover:text-white transition-colors font-mono"
                  >
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
