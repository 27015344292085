export const BRAND_COLORS = {
  primary: {
    pink: {
      light: "rgb(244 114 182)", // pink-400
      default: "rgb(236 72 153)", // pink-500
      dark: "rgb(219 39 119)", // pink-600
      transparent: "rgb(244 114 182, 0.2)",
    },
    purple: {
      default: "rgb(168 85 247)", // purple-500
      transparent: "rgba(168, 85, 247, 0.2)",
    },
  },
  base: {
    black: "#000000",
    white: "#ffffff",
  },
  gradients: {
    background: "linear-gradient(180deg, rgba(0,0,0,1) 0%, #a02863 100%)",
    brand: "linear-gradient(to right, rgb(244 114 182), rgb(168 85 247))",
    overlay:
      "linear-gradient(to bottom right, rgba(244, 114, 182, 0.2), rgba(168, 85, 247, 0.2))",
  },
  meme: {
    backgrounds: [
      "#13111C", // Dark background
      "rgb(244 114 182)", // Pink light
      "rgb(236 72 153)", // Pink default
      "rgb(168 85 247)", // Purple
      "#000000", // Black
      "#ffffff", // White
      "linear-gradient(to right, rgb(244 114 182), rgb(168 85 247))", // Brand gradient
      "linear-gradient(to right, rgba(244, 114, 182, 0.2), rgba(168, 85, 247, 0.2))", // Soft gradient
    ],
    text: [
      "rgb(244 114 182)", // Pink light
      "rgb(236 72 153)", // Pink default
      "rgb(168 85 247)", // Purple
      "#000000", // Black
      "#ffffff", // White
    ],
  },
};
