import { ArrowRight, ArrowRightLeft, Coins, Users, Wallet } from "lucide-react";
import React, { useEffect, useState } from "react";

export const HOW_TO_ID = "how-to-buy";
const Circle = ({ size, color, animationDuration, delay, top, left }) => (
  <div
    className={`rounded-full absolute opacity-60 animate-float`}
    style={{
      width: size,
      height: size,
      backgroundColor: color,
      animationDuration: `${animationDuration}s`,
      animationDelay: `${delay}s`,
      top: `${top}%`,
      left: `${left}%`,
    }}
  />
);

const HowToBuySection = ({ joinThePack }) => {
  const [circles, setCircles] = useState([]);

  useEffect(() => {
    const colors = [
      "#a1466f", // main pink
      "#8e3960", // darker pink
      "#b25780", // lighter pink
      "#4a1f35", // very dark pink
      "#2b1820", // almost black
      "#c17a9a", // soft pink
    ];

    const newCircles = Array.from({ length: 8 }, (_, i) => ({
      id: i,
      size: Math.random() * 300 + 200,
      color: colors[Math.floor(Math.random() * colors.length)],
      animationDuration: Math.random() * 20 + 35,
      delay: Math.random() * 8,
      top: Math.random() * 100,
      left: Math.random() * 100,
    }));

    setCircles(newCircles);
  }, []);

  return (
    <div id={HOW_TO_ID} className="relative bg-black">
      {/* Top gradient transition */}
      <div className="absolute top-0 left-0 right-0 h-32 bg-gradient-to-b from-black to-transparent z-30" />

      <div className="w-full py-24 relative overflow-hidden">
        {/* Animated circle background */}
        {circles.map((circle) => (
          <Circle key={circle.id} {...circle} />
        ))}

        {/* Glass effect overlay */}
        <div className="absolute inset-0 backdrop-blur-md bg-black/40" />

        {/* Original background blur effects */}
        <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-pink-500 rounded-full filter blur-[160px] opacity-10 z-10" />
        <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-purple-500 rounded-full filter blur-[160px] opacity-10 z-10" />

        {/* Main content */}
        <div className="container mx-auto px-4 relative z-20">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
            {/* Left side - Steps */}
            <div className="w-full lg:w-3/5">
              <h2 className="text-5xl md:text-6xl font-bold mb-12 text-white">
                how to buy
                <div className="h-2 w-48 bg-gradient-to-r from-purple-600 to-pink-500 rounded-full mt-2" />
              </h2>

              {/* Steps content remains the same... */}
              <div className="space-y-8">
                {/* Step 1 */}
                <div className="relative group">
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-500 rounded-2xl blur-sm group-hover:blur opacity-50 transition-all duration-300" />
                  <div className="relative bg-black/80 rounded-2xl p-6 hover:bg-zinc-900/90 transition-all duration-300 cursor-pointer border border-purple-500/20">
                    <div className="flex items-start gap-4">
                      <div className="bg-gradient-to-r from-purple-500 to-pink-500 rounded-full p-3 text-white">
                        <Wallet size={24} />
                      </div>
                      <div>
                        <h3 className="text-xl font-bold mb-2 flex items-center gap-2 text-white flex-wrap">
                          step one —
                          <a
                            href="https://phantom.app/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-pink-400 hover:text-pink-300 transition-colors flex items-center"
                          >
                            Create a Phantom wallet
                            <ArrowRight className="ml-1" size={16} />
                          </a>
                        </h3>
                        <p className="text-gray-400">
                          Download Phantom and set up your Solana wallet. It's
                          super easy, we promise!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Step 2 */}
                <div className="relative group">
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-500 rounded-2xl blur-sm group-hover:blur opacity-50 transition-all duration-300" />
                  <div className="relative bg-black/80 rounded-2xl p-6 hover:bg-zinc-900/90 transition-all duration-300 cursor-pointer border border-purple-500/20">
                    <div className="flex items-start gap-4">
                      <div className="bg-gradient-to-r from-purple-500 to-pink-500 rounded-full p-3 text-white">
                        <Coins size={24} />
                      </div>
                      <div>
                        <h3 className="text-xl font-bold mb-2 flex items-center gap-2 text-white flex-wrap">
                          step two —
                          <a
                            href="https://www.binance.com/en/how-to-buy/solana"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-pink-400 hover:text-pink-300 transition-colors flex items-center"
                          >
                            Fund your wallet
                            <ArrowRight className="ml-1" size={16} />
                          </a>
                        </h3>
                        <p className="text-gray-400">
                          Buy some SOL from any exchange and send it to your
                          Phantom wallet
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Step 3 */}
                <div className="relative group">
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-500 rounded-2xl blur-sm group-hover:blur opacity-50 transition-all duration-300" />
                  <div className="relative bg-black/80 rounded-2xl p-6 hover:bg-zinc-900/90 transition-all duration-300 cursor-pointer border border-purple-500/20">
                    <div className="flex items-start gap-4">
                      <div className="bg-gradient-to-r from-purple-500 to-pink-500 rounded-full p-3 text-white">
                        <ArrowRightLeft size={24} />
                      </div>
                      <div>
                        <h3 className="text-xl font-bold mb-2 flex items-center gap-2 text-white flex-wrap">
                          step three —
                          <a
                            href="https://jup.ag/swap/SOL-AuFRvtj7noGruqzBBcKfQ18XsxLfmiaW9FpTWPqMpump"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-pink-400 hover:text-pink-300 transition-colors flex items-center"
                          >
                            Swap on Jupiter
                            <ArrowRight className="ml-1" size={16} />
                          </a>
                        </h3>
                        <p className="text-gray-400">
                          Head to Jupiter, connect your wallet, and swap your
                          SOL for $HAHA. Welcome to the pack!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Step 4 */}
                <div className="relative group">
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-500 rounded-2xl blur-sm group-hover:blur opacity-50 transition-all duration-300" />
                  <div className="relative bg-black/80 rounded-2xl p-6 hover:bg-zinc-900/90 transition-all duration-300 cursor-pointer border border-purple-500/20">
                    <div className="flex items-start gap-4">
                      <div className="bg-gradient-to-r from-purple-500 to-pink-500 rounded-full p-3 text-white">
                        <Users size={24} />
                      </div>
                      <div>
                        <h3 className="text-xl font-bold mb-2 flex items-center gap-2 text-white flex-wrap">
                          step four —
                          <button
                            onClick={joinThePack}
                            className="text-pink-400 hover:text-pink-300 transition-colors flex items-center"
                          >
                            Join our community
                            <ArrowRight className="ml-1" size={16} />
                          </button>
                        </h3>
                        <p className="text-gray-400">
                          Join our Telegram and start laughing with fellow
                          HahaDog holders!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right side - Image */}
            <div className="w-full lg:w-2/5 flex justify-center">
              <div className="relative">
                <div className="absolute -top-6 right-12 transform rotate-12">
                  <div className="bg-gradient-to-r from-purple-500 to-pink-500 p-[1px] rounded-full">
                    <div className="bg-black rounded-full px-4 py-2">
                      <p className="text-white font-bold">
                        Imagine not buying $haha 😂
                      </p>
                    </div>
                  </div>
                </div>
                <img
                  src="/hahadog.png"
                  alt="HahaDog Mascots"
                  className="rounded-2xl shadow-2xl border border-purple-500/20"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom gradient transition */}
      <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black to-transparent z-30" />
    </div>
  );
};

// Add the float animation to Tailwind with gentler movements
const style = document.createElement("style");
style.textContent = `
  @keyframes float {
    0% {
      transform: translate(0, 0) scale(1);
    }
    25% {
      transform: translate(60px, 60px) scale(1.05);
    }
    50% {
      transform: translate(-40px, 30px) scale(0.98);
    }
    75% {
      transform: translate(30px, -60px) scale(1.02);
    }
    100% {
      transform: translate(0, 0) scale(1);
    }
  }
  .animate-float {
    animation: float linear infinite;
  }
`;
document.head.appendChild(style);

export default HowToBuySection;
