import React from "react";

const TallResponsiveWave = () => {
  return (
    <div className="absolute bottom-0 left-0 right-0 z-0 pointer-events-none">
      <svg
        className="w-full h-[35vh] min-h-[280px] max-h-[600px]"
        viewBox="0 0 1440 600"
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0,360L48,370C96,380,192,400,288,430C384,460,480,500,576,470C672,440,768,340,864,340C960,340,1056,440,1152,440C1248,440,1344,340,1392,290L1440,240L1440,601L1392,601C1344,601,1248,601,1152,601C1056,601,960,601,864,601C768,601,672,601,576,601C480,601,384,601,288,601C192,601,96,601,48,601L0,601Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default TallResponsiveWave;
