import React, { useState, useEffect, useMemo } from "react";

const HeroRotator = ({
  children,
  interval = 5000,
  containerHeight = "h-[600px]",
  className = "",
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  // Memoize children array to prevent unnecessary re-processing
  const childrenArray = useMemo(
    () => React.Children.toArray(children),
    [children]
  );

  // Handle automatic rotation with RAF for smoother transitions
  useEffect(() => {
    let timeoutId;
    let rafId;

    const rotate = () => {
      setIsAnimating(true);
      // Use RAF to ensure smooth transition start
      rafId = requestAnimationFrame(() => {
        setCurrentIndex((prev) => (prev + 1) % childrenArray.length);
      });
    };

    timeoutId = setInterval(rotate, interval);

    return () => {
      clearInterval(timeoutId);
      cancelAnimationFrame(rafId);
    };
  }, [childrenArray.length, interval]);

  // Handle animation reset with RAF
  useEffect(() => {
    let rafId;
    const timeoutId = setTimeout(() => {
      rafId = requestAnimationFrame(() => {
        setIsAnimating(false);
      });
    }, 500);

    return () => {
      clearTimeout(timeoutId);
      cancelAnimationFrame(rafId);
    };
  }, [currentIndex]);

  // Early return if no children
  if (!childrenArray.length) return null;

  return (
    <section
      className={`relative w-full overflow-hidden ${containerHeight} ${className}`}
    >
      <div className="absolute inset-0 flex items-center justify-center">
        {/* Map through children and conditionally render with opacity */}
        {childrenArray.map((child, index) => (
          <div
            key={index}
            className={`absolute inset-0 flex items-center justify-center transition-opacity duration-500 ease-in-out
              ${
                index === currentIndex
                  ? "opacity-100 pointer-events-auto"
                  : "opacity-0 pointer-events-none"
              }`}
            aria-hidden={index !== currentIndex}
          >
            {child}
          </div>
        ))}
      </div>
    </section>
  );
};

export default HeroRotator;
