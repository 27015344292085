import * as CANNON from "cannon-es";
import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import ContentOverlay from "../ui/contentOverlay";

const PhysicsPinkRoom = ({ handleNews }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) return;

    // Scene setup
    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#000000"); // Black background to avoid white space

    // Camera setup with dynamic aspect ratio
    const camera = new THREE.PerspectiveCamera(
      75,
      containerRef.current.clientWidth / containerRef.current.clientHeight,
      0.1,
      1000
    );
    camera.position.set(0, 4, 8);

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(
      containerRef.current.clientWidth,
      containerRef.current.clientHeight
    );
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1.5;

    containerRef.current.appendChild(renderer.domElement);

    // Physics world setup
    const world = new CANNON.World({
      gravity: new CANNON.Vec3(0, -9.82, 0),
    });
    world.broadphase = new CANNON.SAPBroadphase(world);
    world.allowSleep = true;

    // Controls with adjusted target height
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.maxPolarAngle = Math.PI / 1.8;
    controls.minPolarAngle = Math.PI / 3;
    controls.enableZoom = false;
    controls.target.set(0, 0, 0); // Adjusted target height

    // Room geometry with adjusted position
    const roomGeometry = new THREE.BoxGeometry(12, 8, 12);
    const baseColor = new THREE.Color("#ff69b4").multiplyScalar(1.2);

    // Room materials
    const wallMaterial = new THREE.MeshPhysicalMaterial({
      color: baseColor,
      side: THREE.BackSide,
      roughness: 0.1,
      metalness: 0.2,
      clearcoat: 1,
      clearcoatRoughness: 0.1,
      reflectivity: 1,
    });

    const floorMaterial = new THREE.MeshPhysicalMaterial({
      color: baseColor.clone().multiplyScalar(0.9),
      side: THREE.BackSide,
      roughness: 0.05,
      metalness: 0.3,
      clearcoat: 1,
      clearcoatRoughness: 0.1,
      reflectivity: 1,
    });

    // Create room
    const room = new THREE.Mesh(roomGeometry, [
      wallMaterial,
      wallMaterial,
      wallMaterial,
      floorMaterial,
      wallMaterial,
      wallMaterial,
    ]);
    room.position.y = 4; // Center the room vertically
    scene.add(room);

    // Physics materials
    const physicsMaterial = new CANNON.Material();

    // Create physics walls and floor
    const groundShape = new CANNON.Box(new CANNON.Vec3(6, 0.1, 6));
    const groundBody = new CANNON.Body({
      mass: 0,
      shape: groundShape,
      material: physicsMaterial,
    });
    world.addBody(groundBody);

    // Create walls
    const wallShapes = [
      new CANNON.Box(new CANNON.Vec3(0.1, 4, 6)), // left
      new CANNON.Box(new CANNON.Vec3(0.1, 4, 6)), // right
      new CANNON.Box(new CANNON.Vec3(6, 4, 0.1)), // front
      new CANNON.Box(new CANNON.Vec3(6, 4, 0.1)), // back
    ];

    const wallPositions = [
      [-6, 4, 0], // left
      [6, 4, 0], // right
      [0, 4, -6], // front
      [0, 4, 6], // back
    ];

    wallShapes.forEach((shape, index) => {
      const body = new CANNON.Body({
        mass: 0,
        shape: shape,
        material: physicsMaterial,
      });
      body.position.set(...wallPositions[index]);
      world.addBody(body);
    });

    // Sphere colors in purple and pink palette
    const sphereColors = [
      "#FF69B4", // Hot Pink
      "#DA70D6", // Orchid
      "#FF1493", // Deep Pink
      "#BA55D3", // Medium Orchid
      "#9370DB", // Medium Purple
      "#8A2BE2", // Blue Violet
      "#FF00FF", // Magenta
      "#DDA0DD", // Plum
    ].map((color) => new THREE.Color(color));

    // Function to get random position within bounds
    const getRandomPosition = (radius) => {
      const margin = radius + 0.5; // Add margin to prevent spawning too close to walls
      return {
        x: (Math.random() - 0.5) * (11 - 2 * margin),
        y: 5 + Math.random() * 3, // Random height between 5 and 8 units
        z: (Math.random() - 0.5) * (11 - 2 * margin),
      };
    };

    // Create spheres with physics
    const sphereMeshes = [];
    const sphereBodies = [];

    const createPhysicsSphere = (radius, color) => {
      const pos = getRandomPosition(radius);

      // Three.js sphere
      const sphereGeometry = new THREE.SphereGeometry(radius, 32, 32);
      const sphereMaterial = new THREE.MeshPhysicalMaterial({
        color: color,
        roughness: 0.1,
        metalness: 0.8,
        clearcoat: 1,
        clearcoatRoughness: 0.1,
        reflectivity: 1,
        ior: 1.5,
      });
      const mesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      scene.add(mesh);
      sphereMeshes.push(mesh);

      // Cannon.js sphere
      const shape = new CANNON.Sphere(radius);
      const body = new CANNON.Body({
        mass: radius * 5,
        shape: shape,
        material: physicsMaterial,
        position: new CANNON.Vec3(pos.x, pos.y, pos.z),
        linearDamping: 0.4,
        angularDamping: 0.4,
      });
      world.addBody(body);
      sphereBodies.push(body);
    };

    // Create spheres with random radiuses between 0.8 and 1.5
    for (let i = 0; i < 8; i++) {
      const radius = 0.8 + Math.random() * 0.7;
      createPhysicsSphere(radius, sphereColors[i]);
    }

    // Contact material for bounce effect
    const contactMaterial = new CANNON.ContactMaterial(
      physicsMaterial,
      physicsMaterial,
      {
        restitution: 0.4,
        friction: 0.5,
      }
    );
    world.addContactMaterial(contactMaterial);

    // Lighting setup with adjusted positions
    const lights = [
      new THREE.AmbientLight(0xffffff, 0.6),
      new THREE.PointLight(0xffd4db, 1),
      ...Array(8)
        .fill()
        .map(() => new THREE.PointLight(0xff69b4, 0.8)),
      ...Array(4)
        .fill()
        .map(() => new THREE.PointLight(0xff1493, 0.6)),
    ];

    // Position lights relative to room position
    lights[1].position.set(0, 7, 0);

    const cornerPositions = [
      [-5, 7, -5],
      [5, 7, -5],
      [-5, 7, 5],
      [5, 7, 5],
      [-5, 4, 0],
      [5, 4, 0],
      [0, 4, -5],
      [0, 4, 5],
      [-3, 1, -3],
      [3, 1, -3],
      [-3, 1, 3],
      [3, 1, 3],
    ];

    lights.slice(2).forEach((light, index) => {
      const pos = cornerPositions[index];
      light.position.set(...pos);
    });

    lights.forEach((light) => scene.add(light));

    // Animation
    const clock = new THREE.Clock();
    const animate = () => {
      requestAnimationFrame(animate);

      const delta = clock.getDelta();
      world.step(1 / 60, delta, 3);

      // Update sphere positions
      sphereMeshes.forEach((mesh, i) => {
        const body = sphereBodies[i];
        mesh.position.copy(body.position);
        mesh.quaternion.copy(body.quaternion);
      });

      // Animate light intensities
      lights.slice(2).forEach((light, index) => {
        const time = Date.now() * 0.001;
        light.intensity = 0.4 + 0.4 * Math.sin(time + index);
      });

      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    // Handle window resize
    const handleResize = () => {
      if (!containerRef.current) return;

      const width = containerRef.current.clientWidth;
      const height = containerRef.current.clientHeight;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (containerRef.current) {
        containerRef.current.removeChild(renderer.domElement);
      }
    };
  }, []);

  return (
    <div className="w-full relative h-screen bg-black" ref={containerRef}>
      {/* Three.js canvas will be inserted here */}
      <ContentOverlay
        images={[
          "/general/10.jpg",
          "/general/9.jpg",
          "/general/8.jpg",
          "/general/7.jpg",
          "/general/6.jpg",
          "/general/5.jpg",
          "/general/15.jpg",
          "/general/14.jpg",
          "/general/13.jpg",
          "/general/12.jpg",
          "/general/1.jpg",
          "/general/11.jpg",
          "/general/26.jpg",
          "/general/25.jpg",
          "/general/24.jpg",
          "/general/23.jpg",
          "/general/2.jpg",
          "/general/22.jpg",
          "/general/21.jpg",
          "/general/20.jpg",
          "/general/19.jpg",
          "/general/18.jpg",
          "/general/3.jpg",
          "/general/17.jpg",
          "/general/4.jpg",
          "/general/16.jpg",
        ]}
        rotationInterval={3000}
        handleNews={handleNews}
      />
    </div>
  );
};

export default PhysicsPinkRoom;
