import { Instagram, LineChart, Send, Twitter } from "lucide-react";
import React from "react";

import Tiktok from "../ui/tiktok";

export const ABOUT_ID = "contact-section";
const AboutSection = () => {
  return (
    <div
      id={ABOUT_ID}
      className="w-full scroll-m-14 bg-black py-20 px-4 md:px-8 lg:px-16"
    >
      <div className="container mx-auto max-w-7xl">
        <div className="grid lg:grid-cols-2 gap-12 lg:gap-20">
          {/* About Content */}
          <div className="space-y-8">
            <div className="inline-block">
              <h3 className="text-pink-500 font-mono text-xl mb-2">
                schadenfreude /shäd′n-froi″də/
              </h3>
              <div
                className="bg-gradient-to-r from-pink-500/10 to-purple-500/10 backdrop-blur-sm 
                            rounded-lg p-4 text-gray-300 font-mono text-sm"
              >
                It is a combination of the German nouns Schaden, meaning
                "damage" or "harm," and Freude, meaning "joy."
              </div>
            </div>

            <h2
              className="text-4xl md:text-5xl lg:text-6xl font-bold text-white leading-tight"
              style={{ fontFamily: "Righteous, cursive" }}
            >
              THE LAUGH
              <br />
              <span className="bg-gradient-to-r from-pink-500 to-purple-500 text-transparent bg-clip-text">
                IS GETTING LOUDER
              </span>
            </h2>

            <p
              className="text-2xl text-gray-300 font-mono leading-relaxed tracking-wider
                         bg-gradient-to-r from-pink-500/10 to-purple-500/10 rounded-lg p-6
                         transform hover:scale-105 transition-transform duration-300"
            >
              BORN FROM YOUR MEANEST REACTION MEMES, HAHADOG IS OUT THERE TO GET
              EVERYONE WHO FUCKS UP! NO DEVS, JUST JUDGEMENT.
            </p>
          </div>

          {/* Socials */}
          <div className="relative">
            <div
              className="absolute inset-0 bg-gradient-to-r from-pink-500/20 to-purple-500/20 
                          rounded-2xl transform -rotate-2"
            ></div>
            <div
              className="relative bg-black/80 backdrop-blur-sm rounded-xl p-8 space-y-6
                          border border-pink-500/20"
            >
              <h3
                className="text-2xl text-white mb-8"
                style={{ fontFamily: "Righteous, cursive" }}
              >
                Join the Laughter
              </h3>

              <div className="grid gap-6">
                <a
                  href="https://t.me/HahaCTO"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-4 text-gray-300 hover:text-white 
                            p-4 rounded-lg bg-gradient-to-r from-pink-500/10 to-purple-500/10
                            transform hover:translate-x-2 transition-all duration-300"
                >
                  <Send className="w-6 h-6" />
                  <span className="font-mono">Join Telegram Community</span>
                </a>

                <a
                  href="https://x.com/HahaDogSol"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-4 text-gray-300 hover:text-white 
                            p-4 rounded-lg bg-gradient-to-r from-pink-500/10 to-purple-500/10
                            transform hover:translate-x-2 transition-all duration-300"
                >
                  <Twitter className="w-6 h-6" />
                  <span className="font-mono">Follow on X</span>
                </a>

                <a
                  href="https://www.instagram.com/hahadogmeme"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-4 text-gray-300 hover:text-white 
                            p-4 rounded-lg bg-gradient-to-r from-pink-500/10 to-purple-500/10
                            transform hover:translate-x-2 transition-all duration-300"
                >
                  <Instagram className="w-6 h-6" />
                  <span className="font-mono">Follow our Instagram</span>
                </a>

                <a
                  href="https://www.tiktok.com/@hahadogmeme"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-4 text-gray-300 hover:text-white 
                            p-4 rounded-lg bg-gradient-to-r from-pink-500/10 to-purple-500/10
                            transform hover:translate-x-2 transition-all duration-300"
                >
                  <Tiktok className="w-6 h-6" />
                  <span className="font-mono">Check our tiktok</span>
                </a>

                {/* <a
                  href="https://dexscreener.com/solana/9atsvkjanc7vgu2onbb6eyh5jaxadxdmne7cup7w9mgz"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-4 text-gray-300 hover:text-white 
                            p-4 rounded-lg bg-gradient-to-r from-pink-500/10 to-purple-500/10
                            transform hover:translate-x-2 transition-all duration-300"
                >
                  <ChartBar className="w-6 h-6" />
                  <span className="font-mono">View on DexScreener</span>
                </a> */}

                <a
                  href="https://www.dextools.io/app/en/solana/pair-explorer/9aTsvKjAnC7vGU2oNbB6EyH5JAxaDXDMNe7CUp7w9MGz"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-4 text-gray-300 hover:text-white 
                            p-4 rounded-lg bg-gradient-to-r from-pink-500/10 to-purple-500/10
                            transform hover:translate-x-2 transition-all duration-300"
                >
                  <LineChart className="w-6 h-6" />
                  <span className="font-mono">Check on DexTools</span>
                </a>
              </div>

              <div
                className="absolute -bottom-4 -right-4 w-24 h-24 bg-gradient-to-br from-pink-500 to-purple-500 
                            rounded-full blur-2xl opacity-20"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
