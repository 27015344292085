import { ChevronDown, ChevronUp, ImageIcon } from "lucide-react";
// components/AssetSelector.jsx
import React, { useEffect, useState } from "react";

const AssetSelector = ({ onSelect }) => {
  const [assets, setAssets] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // This would typically come from an API or configuration
    // For now, we'll hardcode the assets from the public folder
    const memeAssets = [
      { id: 1, name: "hahaDog", path: "/memeGen/hahadog-1.png" },
      { id: 2, name: "haha", path: "/memeGen/haha.png" },
      { id: 3, name: "lol", path: "/memeGen/lol-emoji-1.png" },
      { id: 4, name: "rofl", path: "/memeGen/lol-emoji-2.png" },
      { id: 5, name: "wutt", path: "/memeGen/lol-emoji-3.png" },
      { id: 6, name: "wutt2", path: "/memeGen/lol-emoji-4.png" },
      { id: 7, name: "deal with it", path: "/memeGen/lol-emoji-7.png" },
      {
        id: 8,
        name: "deal with it 2",
        path: "/memeGen/lol-emoji-6.png",
      },
    ];
    setAssets(memeAssets);
    setLoading(false);
  }, []);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-4 py-2 bg-purple-600 hover:bg-purple-700 rounded-lg transition-colors text-white"
      >
        <ImageIcon size={16} />
        <span className="hidden md:inline">Templates</span>
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </button>

      {isOpen && (
        <div className="absolute top-full left-0 mt-2 w-64 max-h-96 overflow-y-auto bg-[#1A1825] rounded-lg shadow-xl border border-purple-500/20 z-50">
          {loading ? (
            <div className="p-4 text-center text-white/60">Loading...</div>
          ) : (
            <div className="grid grid-cols-2 gap-2 p-2">
              {assets.map((asset) => (
                <button
                  key={asset.id}
                  onClick={() => {
                    onSelect(asset.path);
                    setIsOpen(false);
                  }}
                  className="relative group aspect-square overflow-hidden rounded-lg 
                           bg-black/20 hover:bg-black/40 transition-all duration-200
                           focus:outline-none focus:ring-2 focus:ring-purple-500"
                >
                  <img
                    src={asset.path}
                    alt={asset.name}
                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-200"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    <span className="absolute bottom-2 left-2 text-xs text-white font-medium">
                      {asset.name}
                    </span>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AssetSelector;
